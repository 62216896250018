import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  constructor(private viewportScroller: ViewportScroller) {}

  scrollToTopWithAnimation(duration: number = 500): void {
    const start = this.viewportScroller.getScrollPosition()[1];
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      const ease = this.easeInOutCubic(progress);
      const scrollTo = start * (1 - ease);
      this.viewportScroller.scrollToPosition([0, scrollTo]);

      if (timeElapsed < duration) {
        window.requestAnimationFrame(animateScroll);
      }
    };

    window.requestAnimationFrame(animateScroll);
  }
  private easeInOutCubic(t: number): number {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  }
}
