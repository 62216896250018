import { Injectable, inject } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PeakAuthService } from 'peak-shell';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public headers: HttpHeaders = new HttpHeaders();
  authToken: string | null = localStorage.getItem('peakauth:tokenResponse');
  private peakAuthService = inject(PeakAuthService);

  constructor() {}

  public intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    const excludedUrls = ['https://data.peakup.org'];
    const isExcluded = excludedUrls.some((url) => request.url.startsWith(url));
    if (isExcluded) {
      return next.handle(request);
    }

    request = request.clone({
      headers: this.headers,
    });

    this.authToken = localStorage.getItem('peakauth:tokenResponse');

    if (this.authToken) {
      const token = JSON.parse(this.authToken);
      const authReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token.access_token}`,
        },
      });

      return next.handle(authReq).pipe(
        catchError((error) => {
          if (error.status == 401 || error.status == 502) {
            this.logout();
          }
          return throwError(() => new Error(error));
        })
      );
    } else {
      return next.handle(request);
    }
  }

  logout() {
    this.peakAuthService.auth.clients.logout.postAsync();
    localStorage.clear();
    window.location.reload();
  }
}
